<template>
	<div>
		<div class="row">
			<div class="col-lg-12">
				<div class="panel">
				    <div class="panel-body">
				        <h3 class="panel-title"><img :src="require('@/assets/images/icons/settings.svg')" class="panel-icon" alt=""> Cài đặt hệ thống</h3>

				        <!-- flex-column flex-sm-row -->

				        <ul class="nav nav-pills nav-justified flex-column flex-sm-row" role="tablist">
                            <li class="nav-item" v-for="(v, k, index) in tabsSettings" @click.prevent="tabs(k)">
                                <a class="nav-link" :class="{active: index === 0}" data-toggle="tab" :href="'#' + k" role="tab" aria-selected="true">
                                	<img :src="require('@/assets/images/icons/'+ k +'.svg')" height="30" alt="" style="margin-right:3px;">
                                    {{ v }}
                                </a>
                            </li>
                        </ul>

                        <br>

				        <form action="#" @submit.prevent="submit">

				        	<div class="tab-content">
                                <div class="tab-pane" :class="{active: index === 0}" :id="k" role="tabpanel" v-for="(v, k, index) in tabsSettings">
                                	
                                	<div v-if="k === 'settings'">
                                		<div class="form-group">
							        		<label>Tiêu đề :</label>
							        		<input type="text" class="form-control" v-model="settings.site_title">
							        	</div>
							        	<div class="form-group">
							        		<label>Icon ( Link Ảnh ) :</label>
							        		<input type="text" class="form-control" v-model="settings.site_icon">
							        	</div>
							        	<div class="form-group">
							        		<label>Logo :</label>
							        		<input type="text" class="form-control" v-model="settings.site_logo">
							        	</div>
							        	<div class="alert alert-info">
						        			Link Ảnh hoặc chữ cho Logo, nếu là link ảnh thì bắt buộc phải có http hoặc https và định dạng cuối đuôi là jpg, png, gif ( dạng file ảnh )
						        		</div>
							        	<!-- <div class="form-group">
							        		<label>Màu chủ đạo :</label>
							        		<input type="text" class="form-control" v-model="settings.main_color">
							        	</div> -->
							        	<div class="form-group">
							        		<label>Liên hệ hỗ trợ :</label>
							        		<textarea class="form-control" rows="4" v-model="settings.info_support" placeholder="Thông tin hỗ trợ"></textarea>
							        	</div>
							        	<div class="form-group">
							        		<label>Tiền tệ :</label>
							        		<input type="text" class="form-control" v-model="settings.currency">
							        	</div>
							        	<div class="form-group">
							        		<label>Header Script :</label>
							        		<textarea class="form-control" rows="6" v-model="settings.header_script" placeholder="Mã Script riêng, ví dụ mã Chat Plugin trên Messenger để mọi người liên lạc tới Fanpage của bạn"></textarea>
							        	</div>
                                	</div>

                                	<div v-if="k === 'api'">
                                		<div class="form-group">
							        		<label>API Token ( <a :href="'//' + $store.state.owner_data.site_name + '/site_ctv'" target="_blank">Lấy API</a> ) :</label>
							        		<input type="password" class="form-control" placeholder="Mã API Token không hiển thị Public" v-model="settings.api_token">
							        	</div>
							        	<div class="alert alert-danger">
						        			Mã API Token không hiển thị Public nhưng vẫn cập nhật bình thường nếu bạn nhập mã API Token mới!
						        		</div>
                                	</div>

                                	<div v-if="k === 'users'">
                                		<!-- <div class="form-group">
							        		<label>Chuyển tiền nội bộ :</label>
							        		<select class="form-control" v-model="settings.is_users_transfers">
							        			<option value="0">Tắt</option>
							        			<option value="1">Bật</option>
							        		</select>
							        	</div> -->
							        	<div class="form-group">
							        		<label>Kích hoạt khi đăng ký :</label>
							        		<select class="form-control" v-model="settings.is_active_register">
							        			<option value="0">Tắt</option>
							        			<option value="1">Bật</option>
							        		</select>
							        	</div>
                                	</div>

                                </div>
                            </div>


				        	
				        	<button class="btn b-success btn-block">Cập nhật</button>
				        </form>
				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped=""></style>

<script>
	export default {
		data () {
			return {
				tabsSettings: {
					settings: 'Cài đặt chung',
					api: 'API Service',
					users: 'User'
				}
			}
		},
		props: ['settings', 'tabs'],
		created () {

		},
		methods: {
			submit () {
				this.settings.action = 'site';
				this.$http.post('admin/settings', this.settings).then(res => {
					this.$swal('Thông báo', res.body.message, (res.body.status == 1 ? 'success' : 'error'));
				});
			},
		}
	}
</script>